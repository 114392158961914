// Generated by Framer (5c93d09)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {DMgWAmQ2K: {hover: true}};

const cycleOrder = ["DMgWAmQ2K"];

const serializationHash = "framer-qmoP0"

const variantClassNames = {DMgWAmQ2K: "framer-v-1920j4w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, BeDUaZKaB: title ?? props.BeDUaZKaB ?? "Blog"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, BeDUaZKaB, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "DMgWAmQ2K", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qmoP0", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1920j4w", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DMgWAmQ2K"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"DMgWAmQ2K-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Text __fromCanvasComponent alignment={"left"} className={"framer-bl4mb1"} data-framer-name={"Blog"} fonts={["GF;Altone-700"]} layoutDependency={layoutDependency} layoutId={"vU8hsG22L"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Blog</span><br></span></span>"} style={{"--framer-font-family": "\"Altone\", \"Altone Placeholder\", sans-serif", "--framer-font-size": "36px", "--framer-font-style": "normal", "--framer-font-weight": 700, "--framer-letter-spacing": "0em", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "rgb(255, 255, 255)", "--framer-text-decoration": "none", "--framer-text-transform": "capitalize"}} text={BeDUaZKaB} variants={{"DMgWAmQ2K-hover": {"--framer-font-family": "\"Altone Bold\", \"Altone Bold Placeholder\", sans-serif", "--framer-font-weight": 400, "--framer-text-color": "var(--token-e938dcc6-2a0e-4c12-9846-4efab72d34e8, rgb(228, 2, 70))"}}} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({"DMgWAmQ2K-hover": {fonts: ["CUSTOM;Altone Bold"]}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qmoP0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qmoP0 .framer-1bdidw7 { display: block; }", ".framer-qmoP0 .framer-1920j4w { height: 50px; overflow: hidden; position: relative; width: 150px; }", ".framer-qmoP0 .framer-bl4mb1 { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }", ".framer-qmoP0 .framer-v-1920j4w .framer-1920j4w { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"BLKEgwXZ7":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"BeDUaZKaB":"title"}
 * @framerImmutableVariables true
 */
const FramerPdTjddQ3R: React.ComponentType<Props> = withCSS(Component, css, "framer-qmoP0") as typeof Component;
export default FramerPdTjddQ3R;

FramerPdTjddQ3R.displayName = "Archive / Blog tab";

FramerPdTjddQ3R.defaultProps = {height: 50, width: 150};

addPropertyControls(FramerPdTjddQ3R, {BeDUaZKaB: {defaultValue: "Blog", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerPdTjddQ3R, [{family: "Altone Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/PdTjddQ3R:default", url: "https://framerusercontent.com/assets/PpVpSN8600IvniFmaRfauEHiD1g.ttf"}, url: "https://framerusercontent.com/assets/PpVpSN8600IvniFmaRfauEHiD1g.ttf"}])